import React from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Card, Flex, Input, Space, Table, Tag } from 'antd';

import { DateCell } from '@/components';

import { PowerleadAccount } from '@/features/powerlead/account';

import { TableActionsCell } from './components/TableActionsCell';
import { useAccountsViewModel } from './hooks/useAccountsTableViewModel';
import { AccountFilters } from './types';

import styles from './styles.module.scss';

type Props = {
  filters: AccountFilters;
  onChangeFilters(filters: AccountFilters): void;
  clearSearchParams(): void;
};

const TableColumn = Table.Column<PowerleadAccount>;

export const AccountsTable: React.FC<Props> = ({ filters, onChangeFilters, clearSearchParams }) => {
  const { onTableChange, onSearch, pagination, dataSource, isLoading, bagde } = useAccountsViewModel({
    filters,
    onChangeFilters,
  });

  return (
    <Card>
      <Flex className={styles.wrapper} vertical gap="20px">
        {bagde && (
          <Tag color="blue" className={styles.tag}>
            <CloseCircleFilled onClick={clearSearchParams} />
            <span dangerouslySetInnerHTML={{ __html: bagde.text }} />
            <strong>{bagde.id}</strong>
          </Tag>
        )}
        <Space direction="horizontal" className={styles.filters} key="filter">
          <Input.Search
            placeholder="Search email"
            onSearch={onSearch}
            allowClear
            enterButton="Search"
            key="input"
            defaultValue={filters.search}
          />
        </Space>
        <Table
          onChange={onTableChange}
          dataSource={dataSource}
          pagination={{ ...pagination, position: ['topLeft', 'bottomLeft'] }}
          loading={isLoading}
          key="table"
          bordered
          scroll={{ x: 'max-content' }}
        >
          <TableColumn title="ID" dataIndex="uuid" key="uuid" />
          <TableColumn title="Fullname" dataIndex="full_name" key="full_name" sorter />
          <TableColumn title="Email" dataIndex="email" key="email" width={300} sorter />
          <TableColumn title="Role" dataIndex="role" key="role" sorter />
          <TableColumn
            title="CreatedAt"
            dataIndex="created_at"
            key="created_at"
            sorter
            render={(_, item) => {
              return <DateCell date={item.created_at} />;
            }}
          />
          <TableColumn title="Total searches" dataIndex="total_search_count" key="total_search_count" sorter />
          <TableColumn title="Searches current" dataIndex="current_search_count" key="current_search_count" sorter />
          <TableColumn title="Total credits used" dataIndex="total_credits_used" key="current_search_count" sorter />
          <TableColumn
            title="Current credits used"
            dataIndex="current_credits_used"
            key="current_credits_used"
            sorter
          />
          <TableColumn
            title="UTM"
            dataIndex="utm"
            key="utm"
            sorter
            render={(_, item) => {
              const utm = item.utm;
              return utm?.campaign ?? utm?.id ?? utm?.source ?? '';
            }}
          />
          <TableColumn
            title="Actions"
            dataIndex="actions"
            key="actions"
            render={(_, item) => {
              return <TableActionsCell item={item} />;
            }}
          />
        </Table>
      </Flex>
    </Card>
  );
};
