import { FC } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Divider, Flex, Layout, Space, Typography } from 'antd';

import { getAppVersion, useAppType } from '@/features/app';

import styles from './styles.module.scss';

type Props = {
  userEmail: string;
  onLogout(): void;
};

export const AppHeader: FC<Props> = ({ userEmail, onLogout }) => {
  const appType = useAppType();

  return (
    <Layout.Header className={styles.header}>
      <Typography style={{ fontSize: 20, color: '#ffffff' }}>Powerlead Admin</Typography>
      <Card classNames={{ body: styles.userCardBody }} className={styles.userCard}>
        <Space size="middle" className={styles.appInfo}>
          <Typography className={styles.appInfoText}>App Type: {appType}</Typography>
          <Typography className={styles.appInfoText}>App Version: {getAppVersion()}</Typography>
        </Space>
        <Space size="middle">
          <Flex align="center">
            <Space size="middle" className={styles.userButton}>
              <Avatar className={styles.avatar}>{userEmail.substring(0, 2).toUpperCase()}</Avatar>
              <Typography className={styles.userEmail}>{userEmail}</Typography>
            </Space>
          </Flex>
          <Divider type="vertical" className={styles.divider} />
          <Button onClick={onLogout} icon={<LogoutOutlined />} iconPosition="end" />
        </Space>
      </Card>
    </Layout.Header>
  );
};
