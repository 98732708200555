import { FC, PropsWithChildren } from 'react';
import { Layout } from 'antd';

import { AppHeader, AppSider } from './components';

import styles from './styles.module.scss';

type Props = {
  userEmail: string;
  onLogout(): void;
};

export const AppLayout: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  return (
    <Layout className={styles.layout}>
      <AppHeader {...props} />
      <Layout hasSider>
        <AppSider />
        <Layout.Content className={styles.content}>{children}</Layout.Content>
      </Layout>
    </Layout>
  );
};
