import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getPurhcasesQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';
import type { Purchase, PurchaseType } from '../types';

type UsePurchasesFilters = {
  skip?: number;
  limit?: number;
  productId?: string;
  purchaseType?: PurchaseType;
  accountEmail?: string;
  deleted?: boolean;
  canceled?: boolean;
};

export const usePurchases = (filters: UsePurchasesFilters) => {
  const app = useAppType();

  const searchParams = useMemo(() => {
    const sp = new URLSearchParams();

    sp.set('app', app);

    if ('skip' in filters && filters.skip != undefined) sp.set('skip', filters.skip.toString());
    if (filters.limit) sp.set('limit', filters.limit.toString());
    if (filters.productId) sp.set('product_id', filters.productId);
    if (filters.purchaseType) sp.set('purchase_type', filters.purchaseType);
    if (filters.accountEmail) sp.set('account_email', filters.accountEmail);
    if (filters.deleted) sp.set('deleted', filters.deleted.toString());
    if (filters.canceled) sp.set('canceled', filters.canceled.toString());

    return sp;
  }, [filters, app]);

  return useQuery({
    queryKey: [getPurhcasesQueryKey(app, searchParams)],
    queryFn: async () =>
      BillingHttpClient.get<{
        items: Purchase[];
        total?: number;
      }>(app, `/api/v1/purchase/?${searchParams.toString()}`, {
        headers: {
          'X-Syncai-App-Id': app,
        },
      }),
  });
};
